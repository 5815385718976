// Add flags you want to use on the project

export const flagsBuilder = [
  'flagBuilderFollowedByUserGroups',
  'flagBuilderGroupsContentInGroups',
  'flagBuilderLazyLoadSections',
  'flagBuilderPageGlobalStorage',
  'flagBuilderRemoveColumnBackground',
  'flagBuilderUploadVideo',
] as const;

export const flagsIM = [
  'flagImAddAttachment',
  'flagImCreateChannelApi',
  'flagImCreateConversation',
  'flagImEmojiPicker',
  'flagImAbly',
] as const;

const flagsEmailCampaigns = [
  'flagAxpMultiGroupsCampaign',
  'flagAxpNewCampaignsIndex',
  'flagEmailConfigV2',
] as const;

export default [
  ...flagsBuilder,
  ...flagsIM,
  ...flagsEmailCampaigns,
  'flagActivateFrontMonitoringDatadog',
  'flagAnalyticsChangeTitleTags',
  'flagAskCookiePrefWhenChanged',
  'flagAxpNewUserIndex',
  'flagAxpUserIndexWaveTwo',
  'flagBraintree3DSecureSca',
  'flagCurrentSessionContext',
  'flagDsNewAppHeader',
  'flagDsRemoveBlurBackground',
  'flagEvMessagingReactQuery',
  'flagEvAblyWebsockets',
  'flagEvBlockContentUploadCkEditor',
  'flagEvCategoriesListV2',
  'flagEvDeployDsToCategoriesOnBo',
  'flagEvEventsFoPerformance',
  'flagEvEventsFoTicketDownloadAsync',
  'flagEvEventsValidations',
  'flagEvMembershipFoPagination',
  'flagEvMembershipValidations',
  'flagEvMentoringAdminMatch',
  'flagEvMentoringMultipleMentorPrograms',
  'flagEvOmEvents',
  'flagEvPaymentsMovePromocode',
  'flagEvStripeCobrandedCards',
  'flagEvStripeDirectChargeForAllCC',
  'flagEvTinyMce',
  'flagEvVirusScanning',
  'flagFixedPeriodMvpForStanford',
  'flagGoogleMapsComponent',
  'flagHackathonBirthdayWidget',
  'flagLiveFeedDesignImprovements2',
  'flagLogInRefactoring',
  'flagMobileAppsBoOptionalAndroidServiceAccountJson',
  'flagMobileNewDialog',
  'flagMutliSalesTax',
  'flagNewAnalyticsMvp',
  'flagNewGroupBoHeader',
  'flagProfileCustomHeader',
  'flagRefactoBottomLinks',
  'flagRequestUserAccountDeletion',
  'flagSuAttendedEventsModalUserProfile',
  'flagSuEmailSecurity',
  'flagUeA11YSliders',
  'flagUeCookieBannerNewLogic',
  'flagUeDisableStoredFilters',
  'flagUeDsEmbed',
  'flagUeLfDisablingTwitterWidget',
  'flagUeNavigationMobileRedirectLanding',
  'flagUeSearchDeactivateGlobal',
  'flagUeSessionInfoPageCustomRework',
  'flagUeMobileCaretFooter',
  'flagUjbLandingPagesPerCluster',
  'flagUjbLfPagination',
  'flagUjbTripGermanTransportation',
  'flagUoSatismeter',
  'flagUseWhitelabelCustomCertificate',
  'flagEvSuperAdminReadOnly',
] as const;
